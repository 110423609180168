import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Select, useToast } from '@chakra-ui/react'
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { decryptURL, encryptURL } from '../common/CommonFunctions';
import DatePicker from "react-datepicker";



const Navbar = (props) => {
  const {
    totalUsers,
    sortByMethod,
    showModalFun,
    addNewActivity,
    setOnUpdate,
    littleActsDateFilter,
    filterbyMethod,
    openNotificationForm,
    handleCheckBox,
    listType
  } = props
  let location = useLocation();
  let locationName = location.pathname.split('/')[1]
  const Navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const sortFieldFromURL = decryptURL(queryParams.get('sortField'));
  const currentSortOrder = decryptURL(queryParams.get('sortOrder')) || 'DESC';
  const filterFromURL = decryptURL(queryParams.get('filter')) || 'all';
  const [filter, setFilter] = useState(filterFromURL)
  const [sortByFieldConvo, setSortByFieldConvo] = useState(sortFieldFromURL || 'localDateTime')
  const [sortByFieldCarConvo, setSortByFieldCarConvo] = useState('all')
  const [sortByFieldLetters, setSortByFieldLetters] = useState(sortFieldFromURL || 'createdOn')
  const [selectedDate, setSelectedDate] = useState();

  const buttonsArray = [
    { order: "ASC", icon: <FaArrowUp size={20} /> },
    { order: "DESC", icon: <FaArrowDown size={20} /> },
  ]

  const handleClickASC = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('sortOrder', encryptURL('ASC')); // Set sorting order
    queryParams.set('sortField', locationName === 'conversations' ? encryptURL(sortByFieldConvo) : encryptURL(sortByFieldLetters));
    Navigate(`?${queryParams.toString()}`);
  }
  const handleClickDESC = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('sortOrder', encryptURL('DESC')); // Set sorting order
    queryParams.set('sortField', locationName === 'conversations' ? encryptURL(sortByFieldConvo) : encryptURL(sortByFieldLetters));
    Navigate(`?${queryParams.toString()}`);
  }
  const handleChange = (e) => {
    const newSortField = e.target.value;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('sortField', encryptURL(newSortField)); // Update sort field
    queryParams.set('sortOrder', encryptURL(currentSortOrder));  // Ensure order stays the same
    Navigate(`?${queryParams.toString()}`); // Update URL with new sorting parameters
  }
  const handleNotification = () => {
    openNotificationForm(true);
  }
  const handleAddActivity = () => {
    addNewActivity();
    setOnUpdate(false);
  };
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `${longMonth} ${fullYear}`;
    return (
      <span
        title={tooltipText}
        className="flex items-center justify-center p-3 py-2.5 px-5 mb-2 text-sm font-semibold text-gray-700 bg-white/50 rounded-md shadow-md hover:bg-white/50 transition duration-200 ease-in-out dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
      >
        {shortMonth}
      </span>
    );
  };

  const handleOnChangeDatePicker = (date) => {
    setSelectedDate(date);
    localStorage.setItem("dateFilter", date);
    littleActsDateFilter();
  };

  return (
    <nav className='flex-no-wrap h-32 relative flex w-full items-center justify-between bg-[#f4f7fe] py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4'>
      <div className='flex justify-between h-20 w-full'>
        <div className='flex items-center p-3'>
          <p className='text-2xl font-bold'>
            {locationName === 'studyUsers' ? `STUDY USERS (${totalUsers || '0'})` : locationName.toUpperCase()}
          </p>
        </div>
        {locationName === 'letters' && (<div className='flex items-center p-3'>
          <div className='flex items-center p-1'>
            <button onClick={() => Navigate('/createLetter')} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              Create a Letter
            </button>
          </div>
          <div className="flex items-center p-1 space-x-2">
            {buttonsArray.map(({ order, icon }) => (
              <button
                key={order}
                onClick={() => (order === "ASC" ? handleClickASC(order) : handleClickDESC(order))}
                type="button"
                className={
                  `py-2.5 px-5 mb-2 text-sm font-medium focus:outline-none rounded-lg border
                       border-gray-200 ${currentSortOrder === order
                    ? "text-blue-700 bg-gray-100 focus:ring-4 focus:ring-gray-100"
                    : "text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700"}`}
              >
                {icon}
              </button>
            ))}
          </div>
          <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
            <Select value={sortFieldFromURL} onChange={handleChange} bg='white'>
              <option value='createdOn'>Created Date</option>
              <option value='type'>Letter Type</option>
            </Select>
          </div>
        </div>
        )}
        {locationName === 'conversations' && (<div className='flex'>
          <div className="flex items-center p-1 space-x-2">
            {buttonsArray.map(({ order, icon }) => (
              <button
                key={order}
                onClick={() => (order === "ASC" ? handleClickASC(order) : handleClickDESC(order))}
                type="button"
                className={
                  `py-2.5 px-5 mb-2 text-sm font-medium focus:outline-none rounded-lg border
                       border-gray-200 ${currentSortOrder === order
                    ? "text-blue-700 bg-gray-100 focus:ring-4 focus:ring-gray-100"
                    : "text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700"}`}
              >
                {icon}
              </button>
            ))}
          </div>
          <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
            <Select value={sortFieldFromURL} onChange={handleChange} bg='white'>
              <option value='localDateTime'>Updated Date</option>
              <option value='type'>Type</option>
              <option value='isActive'>Is Active</option>
              <option value='expiringOn'>Expiring On</option>
            </Select>
          </div>
        </div>
        )}
        {locationName === "little-acts" && (<div className="flex">
          <div className="flex items-center p-3 py-2.5 px-5 me-2 mb-2">
            <label className="flex items-center  me-2 text-sm font-medium text-gray-900  hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700">
              Created For
            </label>
            <div
              className="flex items-center text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-1 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              <DatePicker
                selected={selectedDate}
                renderMonthContent={renderMonthContent}
                showMonthYearPicker
                dateFormat="MMM / yyyy"
                onChange={(date) => {
                  handleOnChangeDatePicker(date);
                }}
                placeholderText='--select month and Year--'
                className="flex items-center p-3 py-3 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg   hover:bg-gray-100 hover:text-blue-700 focus:outline-none  dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
              />
              <span
                className="flex items-center  text-2xl  text-gray-200 bg-white  dark:text-gray-300 "
              >|
              </span>
              <button onClick={() => {
                localStorage.setItem("dateFilter", '');
                littleActsDateFilter();
              }}
                className="flex items-center p-3 py-3 px-3  text-sm font-medium text-gray-900 bg-white rounded-lg  hover:bg-gray-100 hover:text-blue-700 focus:outline-none  dark:bg-gray-800  dark:text-gray-300 dark:hover:bg-gray-700"
              >All
              </button>
            </div>
          </div>
          <div className="p-3">
            <button
              onClick={handleAddActivity}
              className="flex items-center p-3 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              New Activity
            </button>
          </div>
        </div>
        )}
        {locationName === 'studyUsers' && (<div className='flex items-center p-1'>
          <div className="flex items-center mb-2 px-2">
            <input id="default-checkbox" type="checkbox" value="carUsers"
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => handleCheckBox(e)}
              checked={listType === 'carUsers'}

            />
            <label htmlFor="default-checkbox" className="ml-2 text-md font-medium text-gray-900 dark:text-gray-300">All Users</label>
          </div>

          {listType === 'carUsers' ? <div className='flex items-center p-3 py-2.5 px-1 mb-2'>
            <Select value={filter} placeholder='' onChange={(e) => filterbyMethod(e)} bg='white'>
              <option value='all'>All Users</option>
              <option value='trial'>Trial/Intervention Users</option>
              <option value='control'>Control users</option>
            </Select>
          </div>
            :
            <div className='flex items-center p-3 py-2.5 px-1 me-2 mb-2'>
              <Select value={sortFieldFromURL} onChange={handleChange} bg='white'>
                <option value='localDateTime'>Updated Date</option>
                <option value='enrollmentDate'>Enrollment Date</option>
                <option value='messageCount'>Message Count</option>
              </Select>
            </div>}
          <button onClick={() => showModalFun()} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            Add User
          </button>
        </div>)}
        {/* {locationName === 'dashboard' && (<div className='flex items-center p-1'>
          <button onClick={handleNotification} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            Send Notification
          </button>
        </div>)} */}
      </div>
    </nav>
  )
}

export default Navbar
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { MdCheckCircle, MdCancel } from "react-icons/md"
import { getLettersList } from '../../../services/dataApi/dataProvider'
import { ApiUrlProvide, decryptURL, encryptURL, headerProvider, showErrorMessage } from '../../../common/CommonFunctions'
import { Spinner, useToast } from '@chakra-ui/react'
import Pagination from '@mui/material/Pagination'

const LettersList = () => {
    const Navigate = useNavigate()
    const toast = useToast()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageNumber = parseInt(queryParams.get('page')) || 1
    const sortingField = decryptURL(queryParams.get('sortField')) || 'createdOn'
    const sortingOrder = decryptURL(queryParams.get('sortOrder')) || 'DESC'
    const [lettersList, setLettersList] = useState([])
    const [loader, setLoader] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [itemsPerPage] = useState(10)
    const [filter] = useState({})
    const [sortingLetters, setSortingLetters] = useState({ field: sortingField, order: sortingOrder })
    const getAllLetters = useCallback(async () => {
        try {
            setLoader(true)
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingLetters.field, sortingLetters.order)
            const headers = headerProvider()
            const response = await getLettersList(queryParams, headers)

            if (response) {
                setLettersList(response.response)
                setPageCount(Math.ceil(response.totalCount / itemsPerPage))
            } else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
            setLoader(false)
        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }, [pageNumber, sortingLetters])

    useEffect(() => {
        getAllLetters()
    }, [getAllLetters])

    const handleChange = (event, value) => {
        if (value) {
            Navigate(`?page=${value}&sortField=${encryptURL(sortingLetters.field)}&sortOrder=${encryptURL(sortingLetters.order)}`);
        }
    }
    const sortByMethod = (e) => {
        if (e.field) {
            setSortingLetters({ field: e.field, order: e.order })
            Navigate(`?page=${pageNumber}&sortField=${encryptURL(e.field)}&sortOrder=${encryptURL(e.order)}`);
        }
    }
    const handleClick = (item) => {
        if (item) {
            Navigate(`/letters/${item.id}`, { state: item.id })
        }
    }
    return (
        <div className='h-screen flex'>
            <div className='basis-1/5'>
                <Sidebar />
            </div>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <div>
                    <Navbar sortByMethod={sortByMethod} />
                </div>
                {
                    loader ?
                        <div className='flex justify-center self-center mt-64'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </div> :
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Heading
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Type
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            isActive
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Created On
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        lettersList.map((item) => {
                                            return <tr key={item.id} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{item.heading}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{item.type}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    {item.isActive ? <MdCheckCircle className='text-green-500' size={24} /> : <MdCancel className='text-red-500' size={24} />}
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{(new Date(item.createdOn)).toLocaleDateString()}</p>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                }
                {!loader && <div className='mb-10 flex justify-center h-20 items-center'>
                    <div>
                        <Pagination page={pageNumber} count={pageCount} color="primary" onChange={handleChange} />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default LettersList
import React, { useCallback, useEffect, useState } from 'react'
import { IoMdHome } from "react-icons/io";
import { BsChatSquareText } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { AUTH_ENDPOINT } from '../config';
import { MdLogout } from "react-icons/md";
import Cookies from 'js-cookie';
import { headerProvider, showErrorMessage } from '../common/CommonFunctions';
import Avatar from '@mui/material/Avatar';
import { useToast } from '@chakra-ui/react'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { logout } from '../services/authApi/authProvider';
import { isExpired, decodeToken } from "react-jwt";
import { getUserRole } from '../services/dataApi/dataProvider';
import { GrDocumentUser } from "react-icons/gr";
import { LuClipboardEdit } from "react-icons/lu";








const Sidebar = () => {
    const toast = useToast()
    let location = useLocation();
    const navigate = useNavigate();
    const [adminProfile, setAdminProfile] = useState({})
    const [isSupportAdmin, setIsSupportAdmin] = useState(false);
    const getUserRoleData = useCallback(async () => {
        try {
            const headers = headerProvider();
            const response = await getUserRole(headers);
            if (response) {
                if (response?.response?.role === 'SupportAdmin') {

                    setIsSupportAdmin(true);
                }
            } else {
                showErrorMessage('Server Error', 'No Response from server', 'error', toast)
            }
        }
        catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => { getUserRoleData() }
        , [getUserRoleData]);

    useEffect(() => {
        const credential = localStorage.getItem('credential')
        const myDecodedToken = decodeToken(credential);
        setAdminProfile(myDecodedToken)
    }, []);

    const handleSupportAdminAccess = (e) => {
        e.preventDefault();
        return showErrorMessage('Unauthorized Access', 'You are not authorized to access this section', 'error', toast);

    }
    return (
        <>
            <div className="h-screen border-r flex flex-col">
                <div className="sticky top-0">
                    <div className="mt-5 h-20 flex justify-center items-center gap-4 p-4 ml-2">
                        <div className="basis-1/4">
                            <Avatar
                                alt={adminProfile.picture}
                                src={adminProfile.picture}
                                sx={{ width: 60, height: 60 }}
                            />
                        </div>
                        <div className="basis-3/4">
                            <h1 className="text-lg font-medium">{adminProfile.name}</h1>
                        </div>
                    </div>
                </div>
                <div className="flex-1 overflow-y-auto">
                    <div className="gap-1 flex flex-col justify-center items-center mt-5">
                        <Link to="/dashboard">
                            <div
                                className={`${location.pathname === "/dashboard" ? "bg-slate-100" : "white"
                                    } flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}
                            >
                                <IoMdHome size={26} />
                                <p className="text-xl font-semibold">Home</p>
                            </div>
                        </Link>
                        <Link to="/conversations" onClick={(e) => isSupportAdmin && handleSupportAdminAccess(e)}>
                            <div
                                className={`${location.pathname === "/conversations" ? "bg-slate-100" : "white"
                                    } flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}
                            >
                                <BsChatSquareText size={26} />
                                <p className="text-xl font-semibold">Conversations</p>
                            </div>
                        </Link>
                        {/* <Link to="/little-acts" onClick={(e) => isSupportAdmin && handleSupportAdminAccess(e)}>
                            <div
                                className={`${location.pathname === "/little-acts" ? "bg-slate-100" : "white"
                                    } flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}
                            >
                                <LuClipboardEdit size={26} />
                                <p className="text-xl font-semibold">Little Acts</p>
                            </div>
                        </Link> */}
                        {/* <Link to="/therapist"> */}
                        <div
                            onClick={(e) => isSupportAdmin && handleSupportAdminAccess(e)}
                            className={`${location.pathname === "/therapist" ? "bg-slate-100" : "white"
                                } flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}
                        >
                            <FaUsers size={26} />
                            <p className="text-xl font-semibold">Therapist</p>
                        </div>
                        {/* </Link> */}
                        <Link to="/letters" onClick={(e) => isSupportAdmin && handleSupportAdminAccess(e)}>
                            <div
                                className={`${location.pathname === "/letters" ? "bg-slate-100" : "white"
                                    } flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}
                            >
                                <FaRegEnvelopeOpen size={26} />
                                <p className="text-xl font-semibold">Letters</p>
                            </div>
                        </Link>
                        <Link to="/studyUsers" onClick={(e) => isSupportAdmin && handleSupportAdminAccess(e)}>
                            <div
                                className={`${location.pathname === "/studyUsers" ? "bg-slate-100" : "white"
                                    } flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}
                            >
                                <GrDocumentUser size={26} />
                                <p className="text-xl font-semibold">Study Users</p>
                            </div>
                        </Link>
                        <div
                            className={`cursor-pointer flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}
                            onClick={async () => {
                                try {
                                    const headers = headerProvider();
                                    const response = await logout(headers);
                                    if (response) {
                                        if (response.success) {
                                            Cookies.remove("token");
                                            navigate("/");
                                            showErrorMessage("Logged Out", response.response, "success", toast);
                                        } else {
                                            showErrorMessage("Failed", response.response, "error", toast);
                                        }
                                    } else {
                                        showErrorMessage(
                                            "Server Error",
                                            "The server is not responding. Please try later!",
                                            "error",
                                            toast
                                        );
                                    }
                                } catch (error) {
                                    console.log("This is the error", error);
                                }
                            }}
                        >
                            <MdLogout size={26} />
                            <p className="text-xl font-semibold">Logout</p>
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0">
                    <div className="mb-4 h-20 flex justify-center items-center gap-4 p-4 ml-2">
                        <Logo />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Sidebar
import React, { useEffect } from 'react'
import { AUTH_ENDPOINT } from '../../config';
import {
    useNavigate
} from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { useEditable, useToast } from '@chakra-ui/react'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { login } from '../../services/authApi/authProvider';
import { showErrorMessage } from '../../common/CommonFunctions';




const Login = () => {
    const token = Cookies.get('token')
    const navigate = useNavigate();
    const toast = useToast()
    useEffect(() => {
        if (token) {
            navigate('/dashboard')
        }
    }, [])
    return (
        <div className='h-screen justify-center items-center flex'>
            <div className='items-center'>
                <div className='flex justify-center mb-10'>
                    <div className='w-72'>
                        <Logo />
                    </div>
                </div>
                <div className='min-w-72'>
                    <h1 className='font-bold text-3xl'>
                        Sign In to Mindnotes Admin Panel
                    </h1>
                </div>
                <div className='flex w-92 mt-4 justify-center mb-10'>
                    <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                            localStorage.setItem('credential', credentialResponse.credential)
                            const response = await login(credentialResponse)
                            if (response) {
                                if (response.authenticationToken) {
                                    Cookies.set('token', response.authenticationToken)
                                    navigate('/dashboard')
                                    showErrorMessage('Logged In', 'You have logged in successfully.', 'success', toast)
                                }
                                else {
                                    showErrorMessage('User not found', 'You are not authorised to access this application.', 'error', toast)
                                    navigate('/')
                                }
                            }
                            else {
                                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)  
                            }
                        }}
                        onError={(error) => {
                            console.log('Login Failed', error);
                        }}
                        isSignedIn={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Login
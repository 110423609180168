import React, { useEffect, useState, useCallback } from 'react'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { Formik, Form, Field, replace } from 'formik';
import { IoClose } from "react-icons/io5";
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select, Spinner } from '@chakra-ui/react'
import { createCarUser, getCarConversationList, getCarUsersList } from '../../../services/dataApi/dataProvider';
import { ApiUrlProvide, decryptURL, encryptURL, formatTextWithLinks, headerProvider, showErrorMessage } from '../../../common/CommonFunctions';
import { useToast } from '@chakra-ui/react'
import Pagination from '@mui/material/Pagination';




const StudyUsersList = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const queryParams = new URLSearchParams(location.search);
    const pageNumberFromURL = queryParams.get('page') || 1;
    const sortingFieldFromURL = decryptURL(queryParams.get('sortField')) || 'localDateTime';
    const sortingOrderFromURL = decryptURL(queryParams.get('sortOrder')) || 'DESC';
    const filterFromURL = decryptURL(queryParams.get('filter')) || 'all';
    const listTypeFromURL = decryptURL(queryParams.get('listType')) || 'conversations';
    const [showModal, setShowModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [listType, setListType] = useState(listTypeFromURL)
    const [carUsersList, setCarUserList] = useState([])
    const [totalUsers, setTotalUsers] = useState()
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(pageNumberFromURL)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [filter, setFilter] = useState(filterFromURL)
    const [sortingCarUsers, setSortingCarUsers] = useState({
        field: sortingFieldFromURL,
        order: sortingOrderFromURL
    })
    const [studyUserDetails, setStudyUserDetails] = useState({
        emailId: '',
        redCapId: '',
        userType: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudyUserDetails((inputData) => ({ ...inputData, [name]: value }))
    }
    const handleCheckBox = (e) => {
        if (e) {
            Navigate(`?page=${pageNumber}&sortField=${encryptURL(sortingCarUsers.field)}&sortOrder=${encryptURL(sortingCarUsers.order)}&filter=${encryptURL(filter)}&listType=${encryptURL(e.target.checked ? 'carUsers' : 'conversations')}`);
        }
    }
    const handleChangePage = async (event, value) => {
        if (value) {
            Navigate(`?page=${value}&sortField=${encryptURL(sortingCarUsers.field)}&sortOrder=${encryptURL(sortingCarUsers.order)}&filter=${encryptURL(filter)}&listType=${encryptURL(listType)}`);
        }
    }
    const showModalFun = () => {
        setShowModal(!showModal)
    }
    const handleClick = (item) => {
        if (item.userType !== 'control') {
            if (item) {
                Navigate(`/studyUsers/${item.id}`, {
                    state: {
                        user: item,
                        conversationId: listType === 'carUsers' ? item.conversationId : item.id,
                        userName: listType === 'carUsers' ? item.username : item.userName,
                        userId: listType === 'carUsers' ? item.id : item.userId
                    }
                })
            }
        }
    }
    const getCarUserList = useCallback(async () => {
        try {
            setLoader(true)
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingCarUsers.field, sortingCarUsers.order)
            const headers = headerProvider()
            const response = await getCarUsersList(queryParams, headers)
            if (response) {
                if (response.response.length > 0) {
                    setCarUserList(response.response)
                    setTotalUsers(response.totalCount)
                    setPageCount(Math.ceil(response.totalCount / itemsPerPage))
                }
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
            setLoader(false)
        } catch (error) {
            console.log(error);
        }

    }, [filter, pageNumber, itemsPerPage, sortingCarUsers.field, sortingCarUsers.order, toast])
    const getCarConversations = useCallback(async () => {
        try {
            setLoader(true)
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingCarUsers.field, sortingCarUsers.order)
            const headers = headerProvider()
            const response = await getCarConversationList(queryParams, headers)
            if (response) {
                if (response.response.length > 0) {
                    setCarUserList(response.response)
                    setTotalUsers(response.totalCount)
                    setPageCount(Math.ceil(response.totalCount / itemsPerPage))
                }
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
            setLoader(false)
        } catch (error) {
            console.log(error);
        }

    }, [filter, pageNumber, itemsPerPage, sortingCarUsers.field, sortingCarUsers.order, toast])

    useEffect(() => {
        try {
            if (listType === 'carUsers') {
                getCarUserList()
            }
            else {
                getCarConversations()
            }
        } catch (error) {
            console.log(error);
        }
    }, [filter, pageNumber, itemsPerPage, sortingCarUsers.field, sortingCarUsers.order, toast, showModal, listType, getCarUserList, setListType])

    const filterbyMethod = async (e) => {
        Navigate(`?page=${pageNumber}&sortField=${encryptURL(sortingCarUsers.field)}&sortOrder=${encryptURL(sortingCarUsers.order)}&filter=${encryptURL(e.target.value)}&listType=${encryptURL(listType)}`);
    }

    const sortByMethod = async (e) => {
        if (e.field) {
            // setFilter(e.field)
            // const queryParams = ApiUrlProvide(e.field, pageNumber, itemsPerPage, e.field, e.order)
            // const headers = headerProvider()
            // const response = await getcarUsersList(queryParams, headers)
            // if (response) {
            //     setCarUserList(response.response)
            // }
            // else {
            //     showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            // }
        }

    }

    return (
        <div className='h-screen flex'>
            <div className='basis-1/5'>
                <Sidebar />
            </div>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <Modal
                    isOpen={showModal}
                    ariaHideApp={false}
                    className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
                    contentLabel="Reply Modal"
                >
                    <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-lg h-auto">
                        <div className="cursor-pointer absolute top-2 right-2 text-gray-700 dark:text-gray-300 hover:text-red-500">
                            <IoClose size={30} onClick={showModalFun} />
                        </div>
                        <div>
                            <Formik
                                initialValues={{
                                    replyMessage: "",
                                }}
                                onSubmit={async (values, { setSubmitting }) => {
                                    const headers = headerProvider();
                                    const requestOptions = {
                                        method: "PUT",
                                        headers,
                                        body: JSON.stringify(studyUserDetails),
                                    };
                                    const response = await createCarUser(requestOptions);
                                    if (response) {
                                        if (response.success) {
                                            showErrorMessage(
                                                'User Added',
                                                'User has been added to the CAR study list.',
                                                "success",
                                                toast
                                            );
                                        } else {
                                            showErrorMessage(
                                                "Invalid user",
                                                response.response.errors[0],
                                                "error",
                                                toast
                                            );
                                        }
                                    } else {
                                        showErrorMessage(
                                            "Server Error",
                                            "The server is not responding. Please try later!",
                                            "error",
                                            toast
                                        );
                                    }
                                    setStudyUserDetails({
                                        emailId: '',
                                        userType: studyUserDetails.userType
                                    })
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="p-4">
                                        <div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-700 dark:text-gray-300 mb-2"
                                                    htmlFor="replyMessage"
                                                >
                                                    Add user
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
                                                    type="text"
                                                    name="emailId"
                                                    value={studyUserDetails.emailId}
                                                    placeholder="Enter email"
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    className="block text-gray-700 dark:text-gray-300 mb-2 mt-2"
                                                    htmlFor="replyMessage"
                                                >
                                                    RedCap ID
                                                </label>
                                                <Field
                                                    className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
                                                    type="text"
                                                    name="redCapId"
                                                    value={studyUserDetails.redCapId}
                                                    placeholder="Enter RedCap ID"
                                                    onChange={handleChange}
                                                />
                                                <div className='mt-4'>
                                                    <Select name='userType' placeholder='Select User Type' onChange={handleChange} bg='white'>
                                                        <option value='trial'>Trial/Intervention</option>
                                                        <option value='control'>Control</option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button
                                                    type="submit"
                                                    className={`w-full h-10 rounded-md bg-blue-500 text-white`}>
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </Modal>
                <div>
                    <Navbar
                        listType={listType}
                        handleCheckBox={handleCheckBox}
                        totalUsers={totalUsers}
                        showModalFun={showModalFun}
                        sortByMethod={sortByMethod}
                        filterbyMethod={filterbyMethod}
                    />
                </div>
                {
                    loader ?
                        <div className='flex justify-center self-center mt-40'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </div> :
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
                            {listType === 'conversations' ?
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-white uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Username
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                RedCap Id
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                User Type
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Enrollment date
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Message Type
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Message count
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Updated date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            carUsersList.map((item, index) => {
                                                return <tr key={index} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.userName}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.redCapId}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.userType === 'trial' ? 'Trial/Intervention' : 'Control'}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{new Date(item.enrollmentDate).toLocaleDateString()}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.type ? item.type : 'Not available'}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.messagesCount ? item.messagesCount : 'Not available'}</p>
                                                        {item.isPending && <div>
                                                            <p className='text-green-500 font-semibold'>New Message</p>
                                                        </div>}
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.updatedDate ? new Date(item.updatedDate).toLocaleDateString() : 'Not available'}</p>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-white uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Username
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                RedCap Id
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                User Type
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Enrollment date
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Status
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Message Type
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Message count
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            carUsersList.map((item, index) => {
                                                return <tr key={index} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.username}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.redCapId}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.userType === 'trial' ? 'Trial/Intervention' : 'Control'}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{new Date(item.enrollmentDate).toLocaleDateString()}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        {
                                                            item.isActive ? <p className='text-sm font-semibold text-center bg-green-500 text-white rounded-md p-1'>Active</p>
                                                                : <p className='text-sm font-semibold text-center bg-red-500 text-white rounded-md p-1 px-2'>Inactive</p>
                                                        }
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.msgType ? item.msgType : 'Not available'}</p>
                                                    </td>
                                                    <td className="px-6 py-2">
                                                        <p className='text-base font-semibold'>{item.msgCount ? item.msgCount : 'Not available'}</p>
                                                        {item.isPending && <div>
                                                            <p className='text-green-500 font-semibold'>New Message</p>
                                                        </div>}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                }
                {!loader && <div className='mb-10 flex justify-center h-20 items-center'>
                    <div>
                        <Pagination defaultPage={parseInt(pageNumber)} count={pageCount} color="primary" onChange={handleChangePage} />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default StudyUsersList
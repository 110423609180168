import { API_ENDPOINT, AUTH_ENDPOINT } from "../../config";

export async function getConversationList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/conversations/admin/getAll?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCarUsersList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/getAll/car/users?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCarConversationList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/conversations/admin/car?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSingleConversation(id, headers) {
    try {
        const url = `${API_ENDPOINT}/conversations/admin/${id}`
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function replyToConversation(id, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/conversations/${id}`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {

    }
}

export async function deleteConversation(id, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/conversations/${id}`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error);
    }
}

export async function getLettersList(queryParams, headers) {
    try {
        let url = `${API_ENDPOINT}/letters/admin/getAll?`;
        url += queryParams.join('&');
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSingleLetter(id, headers) {
    try {
        const url = `${API_ENDPOINT}/letters/admin/${id}`
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}


export async function createLetter(requestOptions) {
    try {
        const url = `${API_ENDPOINT}/letters`
        const response = await fetch(url, requestOptions)
        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}


export async function updateLetter(sequenceNumber, requestOptions) {
    try {
        const url = `${API_ENDPOINT}/letters/${sequenceNumber}`
        const response = await fetch(url, requestOptions)
        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function createCarUser(requestOptions) {
    try {
        const url = `${API_ENDPOINT}/upgrade-to-car-user`
        const response = await fetch(url, requestOptions)
        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getUserRole(headers) {
    try {
        const url = `${AUTH_ENDPOINT}/.auth/getRole`
        const response = await fetch(url, { headers });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getLittleActsActivityList(queryParams, headers) {

  try {
    let url = `${API_ENDPOINT}/littleActs/admin?`;
    url += queryParams.join("&");
    const response = await fetch(url, { headers });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getSingleLittleActsActivity(id, headers) {
  try {
    const url = `${API_ENDPOINT}/littleActs/admin/${id}`;
    const response = await fetch(url, { headers });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function createLittleActsActivity(requestOptions) {
  try {
    const url = `${API_ENDPOINT}/littleActs`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteActivity(id, requestOptions) {
  try {
      const url = `${API_ENDPOINT}/littleActs/admin/${id}`
      const response = await fetch(url, requestOptions)
      const data = await response.json()
      return data
  } catch (error) {
      console.log(error);
  }
}

export async function updateActivity(requestOptions){
  try{
    const url = `${API_ENDPOINT}/littleActs/admin/update`
    const response = await fetch(url,requestOptions);
    const data = await response.json();
    return data;
  }catch(error){
    console.log(error);
    return null;
  }
}
export async function addLang(requestOptions){
  try{
    const url = `${API_ENDPOINT}/littleActs/admin/addLang`
    const response = await fetch(url,requestOptions);
    const data = await response.json();
    return data;
  }catch(error){
    console.log(error);
    return null;
  }
}

export async function firstMessageUser(requestOptions) {
    try {
        const url = `${API_ENDPOINT}/conversations/carConversation`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        return data
    } catch (error) {

    }
}

export async function sendNotification(requestOptions){
    try{
      const url = `${API_ENDPOINT}/notify/send`
      const response = await fetch(url,requestOptions);
      const data = await response.json();
      return data;
    }catch(error){
      console.log(error);
      return null;
    }
  }

  
export async function getUserMetaReport(headers,userID) {
  try {
      const url = `${API_ENDPOINT}/user/progress/${userID}`
      const response = await fetch(url, { headers });
      const data = await response.json();
      return data;
  } catch (error) {
      console.log(error);
      return null;
  }
}

export async function getUserScore(headers,userID) {
  try {
      const url = `${API_ENDPOINT}/score/${userID}`
      const response = await fetch(url, { headers });
      const data = await response.json();
      return data;
  } catch (error) {
      console.log(error);
      return null;
  }
}

export async function dropCarUser(requestOptions) {
  try {
      const url = `${API_ENDPOINT}/role/change`
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return data;
  } catch (error) {
      console.log(error);
      return null;
  }
}

